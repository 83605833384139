<template>
  <div>
    <div class="d-flex">
      <feather-icon icon="HardDriveIcon" size="19" />
      <h4 class="mb-0 ml-50">IP information</h4>
    </div>
    <div class="mt-1">
      <b-row>
        <b-col cols="12" sm="3" md="3" lg="2">
          <b-form-group label="IP adress">
            <h5>{{ ip.ip }}</h5>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="2" md="3" lg="2">
          <b-form-checkbox :checked="ip.is_server" disabled>
            Server
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
    <b-row class="d-flex justify-content-around">
      <b-col cols="12" md="12" lg="5" class="mt-2">
        <b-row>
          <b-col cols="12" sm="3" md="3" lg="4" class="mt-1">
            <b-form-checkbox :checked="ip.is_whitelisted" class="mb-2" disabled>
              Whitelisted
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" sm="5" md="7" lg="7">
            <b-button
              class="mb-1"
              :disabled="!!ip.is_banned || loading"
              :variant="'primary'"
              @click="$emit('whitelist')"
            >
              <b-spinner v-if="loading" small type="grow"></b-spinner>
              {{ ip.is_whitelisted ? "Remove Whitelist" : "Whitelist" }}
            </b-button>
          </b-col>
        </b-row>
        <ip-timeline
          :items="ip.whitelist_history"
          title="Whitelist action"
          variant="info"
        />
      </b-col>
      <b-col cols="12" md="12" lg="5" class="mt-2">
        <b-row>
          <b-col cols="12" sm="3" md="3" lg="4" class="mt-1">
            <b-form-checkbox :checked="ip.is_banned" disabled class="mb-2">
              Banned
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" sm="3" md="3" lg="4">
            <b-button
              class="mb-1"
              :disabled="!!ip.is_whitelisted || loading"
              :variant="'primary'"
              @click="$emit('bann')"
            >
              <b-spinner v-if="loading" small type="grow"></b-spinner>
              {{ ip.is_banned ? "Remove Ban" : "Ban" }}
            </b-button>
          </b-col>
        </b-row>
        <ip-timeline
          :items="ip.ban_history"
          title="Ban action"
          variant="warning"
        />
      </b-col>
    </b-row>
    <div v-if="ip.users.length" class="container-md ml-0 pl-0">
      Users
      <ip-users-table :data="ip.users" :fields="fields"> </ip-users-table>
    </div>
  </div>
</template>

<script>
import {
  BCol,
  BFormCheckbox,
  BFormGroup,
  BRow,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import IpTimeline from "./IpTimeline.vue";
import IpUsersTable from "./IpUsersTable.vue";
export default {
  components: {
    BCol,
    BFormCheckbox,
    BFormGroup,
    BRow,
    BButton,
    BSpinner,
    IpTimeline,
    IpUsersTable,
  },
  data() {
    return {
      fields: [
        { key: "email", label: "Email" },
        { key: "name", label: "Name" },
      ],
    };
  },
  props: {
    ip: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped></style>
