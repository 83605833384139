<template>
  <b-modal
    ref="modal"
    cancel-variant="secondary"
    ok-title="Ok"
    cancel-title="Cancel"
    :title="title"
    @ok="$emit('on-save', { reason })"
    centered
  >
    <b-form-group label="IP" label-for="ip">
      <b-form-input id="ip" :value="ipAddress" disabled />
    </b-form-group>
    <b-form-group label="Reason" label-for="reason">
      <b-form-input id="reason" v-model="reason" />
    </b-form-group>
  </b-modal>
</template>

<script>
import { BFormGroup, BFormInput } from "bootstrap-vue";

export default {
  name: "ModalWithReason",
  expose: ["show"],
  props: {
    title: String,
    ipAddress: String,
  },
  components: {
    BFormInput,
    BFormGroup,
  },
  data() {
    return {
      reason: "",
    };
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
  },
};
</script>

<style scoped></style>
