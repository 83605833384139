<template>
  <b-table
    id="ip-users-list"
    class="mt-1"
    :items="data"
    responsive
    primary-key="id"
    :fields="fields"
    show-empty
    empty-text="No matching records found"
  >
    <template #cell(email)="data">
      <div vertical-align="center">
        <b-link
          v-if="data.item.email"
          :to="{ name: 'user', params: { id: data.item.id } }"
          class="font-weight-bold d-block text-nowrap my-1"
        >
          {{ data.item.email }}
        </b-link>
        <template v-else><div class="my-1">Unknown</div></template>
      </div>
    </template>
    <template #cell(name)="data">
      <span>{{ data.item.name }}</span>
    </template>
  </b-table>
</template>

<script>
import { BLink, BTable } from "bootstrap-vue";
export default {
  components: {
    BLink,
    BTable,
  },
  props: {
    data: {
      type: Array,
    },
    fields: {
      type: Array,
    },
  },
};
</script>

<style></style>
