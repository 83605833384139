<template>
  <app-timeline>
    <app-timeline-item
      v-for="item in sortedArray"
      :key="item.id"
      :title="title"
      :subtitle="'Reason: ' + item.reason"
      :time="`
        Added: ${$dateHelpers.formatDate(item.created_at, 'None')} |
        Removed: ${$dateHelpers.formatDate(item.deleted_at, 'None')}`"
      :variant="variant"
    />
  </app-timeline>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { sortArray } from "@/services/utils/util";
export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    title: String,
    items: Array,
    variant: String,
  },
  computed: {
    sortedArray: function () {
      return sortArray(this.items, "created_at");
    },
  },
};
</script>

<style></style>
