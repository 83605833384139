<template>
  <div class="bg-white rounded shadow p-2">
    <table-spinner :loading="pageLoading"></table-spinner>
    <ip-form
      v-if="!pageLoading && ip"
      :ip="ip"
      :loading="loading"
      @bann="banHandler"
      @whitelist="whitelistHandler"
    />
    <modal-with-reason
      v-if="ip"
      ref="ban-modal"
      title="Ban IP address"
      :ip-address="ip.ip"
      @on-save="onBanIp"
    />
    <modal-with-reason
      v-if="ip"
      ref="whitelist-modal"
      title="Add IP address to whitelist"
      :ip-address="ip.ip"
      @on-save="onWhitelistIp"
    />
  </div>
</template>

<script>
import IpForm from "@/layouts/components/ip/IpForm.vue";
import {
  fetchIP,
  deleteFromWhitelist,
  deleteBannedIp,
  postWhitelistIp,
  postBannedIp,
} from "@/services/api/admin";
import { apiErrorHandler } from "@/services/utils/util";
import ModalWithReason from "@/layouts/components/ip/ModalWithReason";
import TableSpinner from "@/layouts/components/TableSpinner.vue";

export default {
  components: {
    ModalWithReason,
    IpForm,
    TableSpinner,
  },
  data() {
    return {
      pageLoading: false,
      loading: false,
      ip: null,
    };
  },
  mounted() {
    this.onFetchIPData();
  },
  created() {
    this.$watch(
      () => this.$route.params.ip,
      () => this.onFetchIPData()
    );
  },
  methods: {
    async onFetchIPData() {
      this.pageLoading = true;
      try {
        this.ip = await fetchIP(this.$route.params.ip);
      } catch (e) {
        apiErrorHandler(e);
        this.$router.go(-1);
      } finally {
        this.pageLoading = false;
      }
    },
    async banHandler() {
      if (this.ip.is_banned) {
        this.loading = true;
        try {
          this.ip = await deleteBannedIp(this.$route.params.ip);
        } catch (e) {
          console.error(e);
          apiErrorHandler(e);
        } finally {
          this.loading = false;
        }
      } else {
        this.$refs["ban-modal"].show();
      }
    },
    async whitelistHandler() {
      if (this.ip.is_whitelisted) {
        this.loading = true;
        try {
          this.ip = await deleteFromWhitelist(this.$route.params.ip);
        } catch (e) {
          console.error(e);
          apiErrorHandler(e);
        } finally {
          this.loading = false;
        }
      } else {
        this.$refs["whitelist-modal"].show();
      }
    },
    async onBanIp({ reason }) {
      this.loading = true;
      try {
        this.ip = await postBannedIp(this.$route.params.ip, { reason });
      } catch (e) {
        console.error(e);
        apiErrorHandler(e);
      } finally {
        this.loading = false;
      }
    },
    async onWhitelistIp({ reason }) {
      this.loading = true;
      try {
        this.ip = await postWhitelistIp(this.$route.params.ip, { reason });
      } catch (e) {
        console.error(e);
        apiErrorHandler(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
